.dashboard-container {
  /* width: 100vw; */
  /* background-color: #f7f7f9; */
  /* background: transparent linear-gradient(180deg, #054163 0%, #3da0d8 100%) 0%
    0% no-repeat padding-box; */
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}
.dashboard-container .loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0dashboard-container 0.8
  ); /* Optional: Semi-transparent background */
  z-index: 1000;
}

.dashboard-container .dashboard-content {
  flex-grow: 1;
  transition: margin-left 0dashboard-container 0.3s ease-in-out;
}

.dashboard-container .dashboard-content.dashboard-container .drawer-open {
  margin-left: 240px;
}

.dashboard-container .drawer {
  /* width: 240px; */
  flex-shrink: 0;
}

.dashboard-container .drawer-paper {
  width: 240px;
  box-sizing: border-box;
}

.dashboard-container .drawer-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  min-height: 64px;
}

.dashboard-container .dashboard-card-container {
  margin-block: 2rem;
  width: 100%;
  display: flex;
  margin: 2rem 1rem;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-container .dashboard-card {
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0dashboard-container 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  transition: all ease-in-out 0dashboard-container 0.1s;
  text-decoration: none;
  border-radius: 10px;
  color: black;
}

.dashboard-container .dashboard-card p,
.dashboard-container h3 {
  text-align: center;
  font-weight: bold;
}

.dashboard-container .dashboard-heading-text {
  width: 20%;
  padding-block: 20px;
  color: var(--primary-color);
  display: inline-block;
}

.dashboard-container .dashboard-card:hover {
  transform: translate(0, -10px);
}

.dashboard-container .position-last {
  z-index: 0;
}
.dashboard-container .nav-bar-left {
  display: flex;
  background-color: #efefef;
  padding: 1px;
  border-bottom: 1px solid #ddd;
  border-radius: 2rem;
  max-width: 60%;
}

.dashboard-container .nav-item {
  /* flex: 1; */
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  color: #6f6f6f;
  border: none;
  border-radius: 25px;
  /* width: 33.33%; */
}

.dashboard-container .active {
  background-color: #054163;
  color: white;
  font-weight: bold;
  border-radius: 2rem;
}

.nav-bar-right {
  display: flex;
  gap: 0.4vw;
  align-content: center;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .dashboard-container .dashboard-card-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    margin: 2rem 0;
  }

  .dashboard-container .dashboard-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 10px;
    color: black;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0dashboard-container 0.1);
  }
}
