.FileViewer .TopNav {
  display: flex;
  gap: 10px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

p.FileName {
  font-weight: 500;
}

.Card {
  width: 24%;
  /* border: 1px solid; */
  min-height: 4rem;
  padding: 7px 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

p.CardText {
  margin: 0;
}

p.CardTitle {
  color: #4bade0;
  font-weight: 600;
  margin-bottom: 1%;
  font-size: 0.8pc;
}

.FileViewer {
  display: flex;
  flex-direction: column;
  gap: 13px;
  /* padding: 20px; */
}

.CardSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 25%; */
  justify-content: space-between;
}
.AgreementContainer {
  display: flex;
  width: 100%;
  align-items: center;
  background: #f1f1f1;
}

.AgreementHeader {
  display: flex;
  align-items: center;
  gap: 0%;
  width: max-content;
  margin-right: 1rem;
}

p.AgreementText {
  margin-block: 10px;
}

input.AgreementCheckBox {
  margin: 0 1rem;
}

select.AgreementSelect {
  /* background: #f5f5f5; */
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 0.5rem;
  width: 100%;
  margin-block: 0.5rem;
}

.FileViewer .button.ActionButtons {
  padding: 6px 3rem;
  border-radius: 20px;
  border: 1px solid #3da0d8;
  background: white;
  color: #3da0d8;
  min-width: 6rem;
}

.FileViewer .button.ActionButtons.activeBtn {
  color: white;
  background: #3da0d8;
}

.FileViewer .fileSideItems {
  width: 100%;
  display: flex;
  gap: 1%;
  align-items: center;
}

.PdfViewerContainer.fullScreenMode {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
}

input.CardInput {
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  font-size: 0.8pc;
  width: 77%;
}

.FileDataInputContainer {
  display: flex;
  flex-direction: column;
  width: 46%;
}

.FileNameInputs {
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-bottom: 1rem;
}
input.CardInput.CardNumberInput {
  width: 21%;
  text-align: center;
}

.CardDropdown {
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  width: 100%;
  font-size: 0.8pc;
}
.fileViewer.fullScreenMode {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
}

.fullscreenIcon {
  background: #e2e0e0;
  color: #333;
  margin: 1rem;
}

.IconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem;
  gap: 1%;
}

.TopIcons {
  display: flex;
  gap: 0.5rem;
}