* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.parentContainer {
  background: transparent linear-gradient(180deg, #054163 0%, #3da0d8 100%) 0%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.side-nav-container {
  flex: 0 0 20%;
  min-width: 20%;
  max-width: 20%;
  align-items: center;
  padding-top: 2%;
  display: flex;
  max-height: 100vh;
  flex-direction: column;
  overflow-y: hidden;
  flex-direction: column;
  position: sticky;
  top: 0;
}

.mainSection {
  flex: 0 0 79%;
  min-width: 78%;
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.mainContent {
  background: white;
  border-radius: 42px;
  min-height: 89vh;
  width: 100%;
  padding: 2%;
  margin: 1% 1% 0 0;
  flex: 1;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  /* width: 97%; */
  margin-right: 1%;
  margin-block-start: 2%;
}

p.footerText {
  margin: 0;
  padding: 0;
  color: white;
}

.mainSection .table-container {
  padding-top: 2%;
}

.table-container .table-container {
  padding: 0;
}

.tableContainer tr {
  cursor: pointer;
}
