 .container {
    width: 100%;
    max-width: 700px; /* Adjust max-width for larger container */
    /* padding: 20px; */
    border-radius: 15px;
    background-color: white;
    /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
    /* margin: 10vh auto; */
  }
  
  .input-box {
    background-color: #f5f5f5;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-bottom: 20px;
    /* text-decoration: none; */
  }
  
  .BtnContainer {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
    margin-top: 15px;
  }
  button.ActionButtons {
    padding: 6px 3rem;
    border-radius: 20px;
    border: 1px solid #3da0d8;
    background: white;
    color: #3da0d8;
    min-width: 6rem;
  }
  
  
  button.ActionButtons.activeBtn {
    color: white;
    background: #3da0d8;
  }