.html-editor {
  padding: 20px;
}

.editor-title {
  margin-bottom: 16px;
}

.edit-button {
  margin-top: 20px;
}

.editor-content table {
  border: 1px solid black;
  border-collapse: collapse;
  padding-block: 0.5rem;
}
.editor-content table tr:first-child {
  background-color: #d9e2f3;
}

.editor-content td,
.editor-content th {
  border: 1px solid black;
  padding: 0.5rem 0.5rem;
}
.editor-content {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  margin-top: 10px;
  position: relative;
  font-family: Calibri, sans-serif;
}

.editor-content:focus-visible,
.editor-content:active,
.editor-content:hover,
.editor-content:focus-within {
  outline: 1px solid #ccc;
}

.resize-handle {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 10px;
  height: 10px;
  background: blue;
  cursor: se-resize;
}

.button-group {
  position: relative;
  margin-block: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1%;
  bottom: 1rem;
  float: right;
}

.save-button {
  margin-right: 10px;
}
/* 
.editor-content body {
  font-family: Calibri, sans-serif;
  line-height: 1.6;
  color: #333;
}

.editor-content h1,
.editor-content h2,
.editor-content h3,
.editor-content h4,
.editor-content h5,
.editor-content h6 {
  color: #2c3e50;
}

.editor-content p {
  margin-bottom: 1em;
  color: red;
}

.editor-content label {
  font-weight: bold;
  margin-right: 0.5em;
}

.editor-content a {
  color: #3498db;
  text-decoration: none;
}

.editor-content a:hover {
  text-decoration: underline;
}

.editor-content table {
  border-collapse: collapse;
  width: 100%;
}

.editor-content th,
.editor-content td {
  padding: 0.5em;
  border: 1px solid #ddd;
}

.editor-content th {
  text-align: left;
  background-color: #4caf50;
  color: white;
} */
