.TopNav {
  display: flex;
  gap: 10px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
}
.pageDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.backButton {
  background-color: #ececec;
}
p.sectionHeading {
  font-weight: 500;
  margin: 0;
  font-size: 1rem;
}
