.docUpload p {
  margin: 0;
}

p.label {
  /* color: var(--unnamed-color-000000); */
  text-align: left;
  font: normal normal medium 24px/29px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin: 0;
}

p.label::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

.DocUpload {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* margin-block: 1rem; */
}

.formTopInputs {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: space-between;
}

.formTopInputs .DocUpload,
.formTopInputs .DisplayName,
.formTopInputs .OwnerDepartment {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-block: 1rem;
  width: 33.33%;
  min-height: 8rem;
}

.formInputsText {
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 6px;

  padding: 0.5rem;
  width: 100%;
  margin-block: 0.5rem;
}
.formInputsFile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 6px;

  padding: 0.5rem;
  width: 100%;
  margin-block: 0.5rem;
}

.formInputsFile input[type="file"] {
  display: none;
}

.formInputsFile span {
  width: 100%;
  text-align: left;
}

input#fileInput {
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  border-radius: 10px;
}

#fileInput::file-selector-button {
  margin-right: 20px;
  border: 1px solid #f5f5f5;
  background: #054163;
  padding: 10px 20px;
  /* border-radius: 10px; */
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

#fileInput::file-selector-button:hover {
  background: #054163;
}

.formInputsFile button {
  background: #2196f3;
  color: white;
  border-radius: 6px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
select.formInputsDrpDown {
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 0.5rem;
  width: 100%;
  margin-block: 0.5rem;
}
.formBottomInputs {
  gap: 3%;
  margin-block: 2rem;
}
.formBottomInputs .Category,
.formBottomInputs .DocumentNumber,
.formBottomInputs .OwnerName,
.formBottomInputs .Version {
  width: 25%;
  min-height: 6.6rem;
}
.BtnContainer {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  margin-block-start: 1rem;
}
button.ActionButtons {
  padding: 6px 3rem;
  border-radius: 20px;
  border: 1px solid #3da0d8;
  background: white;
  color: #3da0d8;
  min-width: 6rem;
}

button.ActionButtons.activeBtn {
  color: white;
  background: #3da0d8;
}
input#fileInput {
  border: 1px solid #d3d3d3;
  background: #f5f5f5;
  border-radius: 10px;
}

.docUpload .formContainer {
  padding: 0 2rem;
}

.error {
  margin-block: 0 !important;
  width: 100%;
  color: red;
}

.formInputs {
  display: flex;
  padding-block: 2pc;
  flex-direction: column;
}

.formSubContainer {
  display: flex;
  width: 100%;
  gap: 5%;
  min-height: 6rem;
}

.formSubContainer div {
  width: 47%;
  margin-block-end: 0.5rem; /* min-height: 1rem; */
}
