input.searchBar {
  border: none;
  background: transparent;
  width: 85%;
  height: auto;
  padding-block: 2%;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  margin-left: 1rem;
}

input.searchBar:hover,
input.searchBar:focus {
  outline: none;
}

button.searchBtn {

  transition: all 0.2s ease-in-out;
}

button.searchBtn:hover,
button.searchBtn:focus {
  outline: none;
  background: #d3d3d3;
  color: white;
}

button.searchBtn:active {
  transform: scale(0.98);
}

.SearchBarContainer::hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.SearchBarContainer {
  background: #f5f5f5;
  width: 49%;
  display: flex;
  border: 1px solid #d3d3d3;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  animation: fadeIn 0.5s ease-in-out;
  border-radius: 6px;
  margin-block-start: 1%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
