.MuiButtonBase {
  z-index: 89;
}

.loaderComponent {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  background-color: #ffffffbf;
  position: fixed;
  justify-content: center;
}

.loaderBar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: #a7a5a5fc;
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.loaderBar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: 0.25s;
}

.loaderBar:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #0a1a23;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
