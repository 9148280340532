.dashboard-adduser-container .add-user-container {
  width: 100%;
  /* margin: 5% auto;  */
  padding: 0 2rem;
  padding-block: 2pc 0;
  border-radius: 8px;
}

.dashboard-adduser-container .add-user-container h2 {
  margin-bottom: 20px;
}

.dashboard-adduser-container .add-user-container .form-row {
  display: flex;
  gap: 5%;
  /* padding: 0.5rem; */
}

.dashboard-adduser-container .add-user-container input,
.add-user-container select {
  flex: 1;
  padding: 10px;
  border: 0.5px solid #ccc;
  border-radius: 6px;
}

.dashboard-adduser-container .add-user-container .form-row .input-box {
  max-height: 44px;
  background: #f5f5f5;
  margin-block: 0.5rem;
}

.dashboard-adduser-container .add-user-container .form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 35px;
}

.dashboard-adduser-container .BtnContainer {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  margin-top: 15px;
}

button.ActionButtons {
  padding: 6px 3rem;
  border-radius: 20px;
  border: 1px solid #3da0d8;
  background: white;
  color: #3da0d8;
  min-width: 6rem;
}

button.ActionButtons.activeBtn {
  color: white;
  background: #3da0d8;
}

.mobileNumber::-webkit-outer-spin-button,
.mobileNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.field-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; /* Ensure error messages stay within the field box */
  margin-bottom: 18px; /* Adjusted to provide enough space for error messages */
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  position: initial; /* Keeps the error message below the input field */
  bottom: 0px; /* Ensures error message is properly aligned */
}

input.input-box,
select.input-box {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Adjust form-row to be flexible */
.dashboard-adduser-container .add-user-container .form-row {
  display: flex;
  justify-content: space-between;
  min-height: 5rem;
  /* margin-bottom: 20px; */
}

.add-user-container .passwordFields {
  max-height: 44px;
  background: #f5f5f5;
  margin: 0px;
  display: flex;
  border-radius: 6px;
  border: 0.5px solid #ccc;
  align-items: center;
  padding: 10px 10px 10px 0;
  margin-block: 0.5rem;
}

.add-user-container .passwordFields input {
  border: none !important;
  background: transparent;
}
.passwordFields input:focus-visible {
  outline: none;
}
