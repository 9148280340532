.dms_dashboard {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 0;
    overflow-x: hidden;
  }
  
  .dms_dashboard_table {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .headBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 0rem;
  }
  
  .subTitle {
    font-weight: bold;
    font-size: larger;
    color: var(--secondary-color);
  }
  
 
  
  .headBlock p {
    font-size: smaller;
  }
  
  .tableBlock {
    width: 100%;
    overflow-x: scroll;
  }
