.body2{
border-bottom: 1px solid rgba(255, 255, 255, 0.12);
padding-top: 0.75em;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
  
}

@media screen and (max-width: 768px) {
Box{
    display: none;
}
}