/* Main container styling */
.dashboard-updateuser-container .add-user-container {
  width: 100%;
  padding: 0 2rem;
  padding-block: 2pc 0;
  border-radius: 8px;
}

/* Heading styles */
.dashboard-updateuser-container .add-user-container h2 {
  margin-bottom: 20px;
}

/* Form row styling with flexible gap and alignment */
.dashboard-updateuser-container .add-user-container .form-row {
  display: flex;
  gap: 5%;
  justify-content: space-between;
  padding: 0.5rem;
}

/* Input and select element styling */
.dashboard-updateuser-container .add-user-container input,
.add-user-container select {
  flex: 1;
  padding: 10px;
  border: 0.5px solid #ccc;
  border-radius: 6px;
}

/* Styling for individual input fields inside form row */
.dashboard-updateuser-container .add-user-container .form-row .input-box {
  max-height: 44px;
  background: #f5f5f5;
  margin-block: 0.5rem;
}

/* Styling for form actions (buttons area) */
.dashboard-updateuser-container .add-user-container .form-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 35px;
}

/* Button container to align the buttons properly */
.dashboard-updateuser-container .BtnContainer {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  margin-top: 15px;
}

/* Styling for buttons */
button.ActionButtons {
  padding: 6px 3rem;
  border-radius: 20px;
  border: 1px solid #3da0d8;
  background: white;
  color: #3da0d8;
  min-width: 6rem;
}

button.ActionButtons.activeBtn {
  color: white;
  background: #3da0d8;
}

/* Remove spin buttons from mobile number input */
.mobileNumber::-webkit-outer-spin-button,
.mobileNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Styling for field container */
.field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative; /* To position error message properly */
  margin-bottom: 0px; /* Adjusted margin to accommodate error message */
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  position: initial; /* Ensure error message stays below input */
  bottom: 0px; /* Align error message under the input field */
}

/* Input field styling */
input.input-box,
select.input-box {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-block: 0.5rem;
}

/* Adjust form-row to be flexible and have proper alignment */
.dashboard-updateuser-container .add-user-container .form-row {
  display: flex;
  justify-content: space-between;
  min-height: 5rem;
  /* margin-bottom: 20px; */
}
