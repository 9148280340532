:root {
  --primary-color: #4bade0;
  --secondary-color: #091a23;
  --font-size-h1: 2.5rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.75rem;
  --font-size-h4: 1.5rem;
  --font-size-h5: 1.25rem;
  --font-size-h6: 1rem;
  --font-weight-h1: 700;
  --font-weight-h2: 600;
  --font-weight-h3: 600;
  --font-weight-h4: 600;
  --font-weight-h5: 500;
  --font-weight-h6: 500;
  --line-height-h1: 1.2;
  --line-height-h2: 1.3;
  --line-height-h3: 1.3;
  --line-height-h4: 1.4;
  --line-height-h5: 1.4;
  --line-height-h6: 1.5;
}

* {
  scroll-behavior: smooth;
}
#root {
  padding: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 0 !important;
  margin: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0.5rem 0;
  /* color: var(--primary-color); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #707070;
}

::-webkit-scrollbar {
  right: 0;
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 0.3rem;
  height: 0.3rem;
}
