.dashboard-container .header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
    margin-right: 0;

}

.dashboard-heading-text {
    margin: 0; /* Remove default margin */
  }
  
  .dashboard-container .add-category-btn {
    display: flex;
    align-items: center;
    /* margin-right: 5%; */
    color: white;
    background: #3da0d8;
    border-radius: 29px;
    padding: 5px 2.5rem;
    border: none;
    gap: 5px;
  }
  
  .manage-category-dashboard .table-container {
    margin-block: 0;
  }
  