
.dashboard-heading-text {
    margin: 0; /* Remove default margin */
}

.dashboard-container .add-role-btn {
    display: flex;
    align-items: center;
    /* margin-right: 5%; */
    color: white;
    background: #3da0d8;
    border-radius: 29px;
    padding: 5px 3rem;
    border:none;
    gap: 5px;
}

.role-dashboard .table-container {
    margin-block: 0;
  }