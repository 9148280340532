ul.side-nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
  overflow-y: auto;
  margin: 0;
  padding: 0 1rem;
  height: 100%;
  margin-top: 10%;
  gap: 7px;
}

li.side-nav-item {
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  animation: slideIn 0.5s ease-in-out;
}

a.side-nav-link,
.download-vessel {
  display: flex;
  color: white;
  text-decoration: none;
  border-radius: 31px;
  width: 100%;
  padding: 9px 9px 9px 21px;
  align-items: center;
  align-content: center;
  gap: 10px;
  transition: all 0.2s ease-in-out;
}

a.side-nav-link:hover,
.side-nav-accordian,
.download-vessel:hover {
  background-color: white;
  color: #054163;
  cursor: pointer;
}

a.side-nav-link.activeItem {
  display: flex;
  background: white;
  color: #054163;
  transition: all 0.2s ease-in-out;
}

p.side-nav-text {
  margin: 0;
}

.side-nav-logo {
  width: 55%;
  height: auto;
}

.side-nav-logo img {
  width: 100%;
}

.navItemChildren:has(.activeItem) {
  display: flex;
  flex-direction: column;
  margin-block: 3%;
  background: white;
  flex-direction: column;
  color: #054163;
  position: relative;
  border-radius: 31px;
  transition: slideIn 0.2s ease-in-out;
}
.activeAccordianItem {
  background: #3da0d8;
  padding-left: 5px;
  padding-block: 12px;
  color: white;
  border-radius: 10px 0 0 10px;
}
.accordion-icon {
  transition: transform 0.3s ease;
  position: absolute;
  right: 7%;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
